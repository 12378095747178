import { Category, HeroImage, Intro, MobileDesign, Pagination, Section } from "@components/Work"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Arrow } from "@components/UI"
import { VimeoPlayer } from "@components/VimeoPlayer"
import { usePageSetup } from "@components/usePageSetup"
import workData from "@json/work.json"
import FontsDesktop from "@svg/work/ad/fonts-desktop.svg"
import FontsMobile from "@svg/work/ad/fonts-mobile.svg"
import FontsTablet from "@svg/work/ad/fonts-tablet.svg"
import LogoOne from "@svg/work/ad/logo-01.svg"
import LogoTwo from "@svg/work/ad/logo-02.svg"
import Vimeo from "@u-wave/react-vimeo"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import React from "react"

const ADPage = ({ data }) => {
  const {
    designSystemDesktopImage,
    designSystemMobileImage,
    webpageOneImage,
    webpageTwoImage,
    mobileOneImage,
    mobileTwoImage,
    mobileThreeImage,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    videoPosterDesktopImage,
    galleryOneImage,
    galleryTwoImage,
    galleryThreeImage,
    galleryFourImage,
  } = data
  const { name, headline, theme, url } = workData["ad"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="ad" />
        <Category>B2B branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>An industrial-strength B2B makeover.</Intro.Title>
            <div className="mt-10">
              <p>
                AD is a fast-growing $75B industrial buying and marketing group that found itself in the midst of an
                identity crisis.
              </p>
              <p>
                After years of expanding through mergers and acquisitions, their brand had become fragmented and it
                became difficult to tell a clear and compelling story about the meaningful value they provide to
                potential new members.
              </p>
              <p>
                They turned to JK to cut through the clutter and create a single powerful story that lets their unique
                advantages shine.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Branding</li>
            <li>Messaging</li>
            <li>Visual identity</li>
            <li>Brand architecture</li>
            <li>Brand launch &amp; development</li>
            <li>Website design &amp; development</li>
            <li>Campaign development</li>
            <li>Motion graphics &amp; live video</li>
            <li>Photography</li>
            <li>Art &amp; graphic design</li>
            <li>Print &amp; packaging</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-8 xl:col-span-9">
            <Section.Title>A focus on what sets them apart.</Section.Title>
            <Section.Description>
              <p>
                Through our collaborative branding process, we worked hand-in-hand with key client stakeholders to build
                a brand based on what AD does best: Teaming up to help their members outperform the market.
              </p>
              <p>
                This was the foundation of a strong brand position—Together, we win.—that lets AD tell a singular strong
                story across seven industries and three countries.
              </p>
              <p>
                It’s a tagline that quickly gets to the value AD delivers, reflects their focus on collaboration, and is
                something that no one else in their space can say. All told, it’s a powerful, ownable brand position for
                a uniquely capable organization.
              </p>
            </Section.Description>
          </Section>
          <div className="mt-clamp-17-22">
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-1/2">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" className="w-full pointer-events-none">
                        <LogoOne />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="200" className="w-full pointer-events-none">
                        <LogoTwo />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <Vimeo video="606515378" controls={false} responsive muted autoplay />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-25-41">
          <div className="container flex justify-center">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Colors
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-16">
                <div className="w-full pb-5 pl-5 text-white pt-39" style={{ background: "#44693D" }}>
                  <span className="font-bold text-18">#44693D</span>
                </div>
                <div className="grid w-full grid-cols-1 text-white sm:grid-cols-2 lg:grid-cols-4">
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#333F48" }}>
                    <span className="font-bold text-18">#333F48</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#D1CCBD" }}>
                    <span className="font-bold text-18">#D1CCBD</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#F8E08E" }}>
                    <span className="font-bold text-18">#F8E08E</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#7C878E" }}>
                    <span className="font-bold text-18">#7C878E</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-34-42">
          <div className="container flex justify-center">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Brand typography
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-6">
                <div className="display-desktop">
                  <FontsDesktop />
                </div>
                <div className="display-tablet">
                  <FontsTablet />
                </div>
                <div className="display-mobile">
                  <FontsMobile />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-clamp-25-44 pt-clamp-24-37" style={{ background: "#333F48" }}>
          <Section className="col-span-9 text-white xl:col-span-10">
            <Section.Title className="text-white">A well documented design system.</Section.Title>
            <Section.Description>
              <p>
                Comprehensive brand guidelines shared AD’s robust design system and their new messaging, assuring their
                creative partners understood how to implement the brand while creating consistency across all
                communications touchpoints.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative flex justify-center mt-clamp-15-22">
            <div className="absolute inset-0 flex">
              <div className="w-full mt-auto aspect-w-326 aspect-h-170 sm:aspect-w-1436 sm:aspect-h-270 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div data-aos="fade-up" className="w-full md:w-10/12">
              <div className="hidden sm:block">
                <GatsbyImage image={getImage(designSystemDesktopImage)} alt="Design System" />
              </div>
              <div className="sm:hidden">
                <GatsbyImage image={getImage(designSystemMobileImage)} alt="Design System" />
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-38-71">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Two stories living in harmony on one website.</Section.Title>
            <Section.Description>
              <p>
                AD serves as a bridge between distributors and suppliers—and they have a lot to offer both audiences. To
                make sure the benefits were crystal clear to both groups, we created two parallel user paths through the
                website, using different imagery and language for each. This allowed AD to tell a compelling, focused
                story to each audience without bogging users down with information that isn’t relevant to them.
              </p>
              <p>
                And because AD’s size and strength gives them unequaled purchasing power—a key differentiator that’s
                very attractive to prospects and partners—we created an eye-catching animated graphic on the homepage
                featuring the impressive statistics that highlight AD’s massive scale.
              </p>
            </Section.Description>
          </Section>

          <div className={`${themeColor} mt-clamp-22-29`}>
            <div className="container relative flex justify-center">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-1436 aspect-h-243 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="flex flex-col w-full lg:flex-row md:w-10/12 gap-x-4">
                <div className="flex w-full lg:block lg:w-1/2">
                  <div className="w-full sm:w-5/6 lg:w-full">
                    <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                      <GatsbyImage image={getImage(webpageOneImage)} alt="AD webpage - 1" />
                    </div>
                  </div>
                </div>
                <div className="flex w-full lg:block lg:w-1/2 pt-clamp-14-37">
                  <div className="w-full ml-auto sm:w-5/6 lg:w-full">
                    <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                      <GatsbyImage image={getImage(webpageTwoImage)} alt="AD webpage - 2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative mt-clamp-15-30">
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto aspect-w-326 aspect-h-170 sm:aspect-w-1436 sm:aspect-h-270 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <MobileDesign>
                <MobileDesign.Phone className="phone-1">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileOneImage)}
                    alt="AD mobile - 1"
                  />
                </MobileDesign.Phone>
                <MobileDesign.Phone className="phone-2 sm:pt-clamp-12-14">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileTwoImage)}
                    alt="AD mobile - 2"
                  />
                </MobileDesign.Phone>
                <MobileDesign.Phone className="phone-3 sm:pt-clamp-24-44">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-3xl"
                    image={getImage(mobileThreeImage)}
                    alt="AD mobile - 3"
                  />
                </MobileDesign.Phone>
              </MobileDesign>
            </div>
          </div>
        </section>

        <section className="pt-clamp-35-50">
          <Section className="col-span-10 xl:col-span-9">
            <Section.Title>A brand built to last. </Section.Title>
            <Section.Description>
              <p>
                With an engaging new brand in hand, we also refreshed AD’s print collateral, helping to seal the deal in
                a tangible way with prospective new members.
              </p>
              <p>
                And over time, the brand’s adaptable visual style has allowed us to put a spin on the design for annual
                meeting themes, special editions of their in-house magazine, one-off events, digital campaigns, and
                more, all while still staying true to the brand’s foundations.
              </p>
            </Section.Description>
          </Section>

          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-55-72">
          <Section className="col-span-10 xl:col-span-9">
            <Section.Description>
              <p>
                It’s a brand built to last and a relationship that has also endured and evolved, because together JK and
                AD have worked to successfully optimize the impact of every communications touchpoint, which has helped
                to fuel their continued growth.
              </p>
              <p>Or to put it another way—Together, we win.</p>
            </Section.Description>
          </Section>

          {/* VIDEO */}
          <div className="relative mt-clamp-20-30 pb-clamp-20-42" style={{ background: "#333F48" }}>
            <div className="absolute inset-0">
              <div className="w-full bg-blue aspect-w-1436 aspect-h-120 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="container flex justify-center">
              <div data-aos="fade-up" className="w-full lg:w-10/12">
                <div className="relative aspect-w-1920 aspect-h-1080">
                  <VimeoPlayer id="292389864">
                    <VimeoPlayer.Poster>
                      <div className="w-full h-full">
                        <GatsbyImage
                          className="w-full h-full"
                          image={getImage(videoPosterDesktopImage)}
                          alt="AD Video Poster"
                        />
                      </div>
                    </VimeoPlayer.Poster>
                  </VimeoPlayer>
                </div>
              </div>
            </div>
            <div className="container grid-cols-6 sm:grid lg:grid-cols-12">
              <div className="col-span-5 lg:col-start-3 lg:col-span-6">
                <p data-aos="fade-up" className="text-white text-clamp-14-18 mt-7 pl-11 lg:pl-0">
                  An accessible video style reflects the one-on-one relationships upon which AD’s organization has been
                  built.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-37-56">
          <div className="container relative">
            <div className="grid-work-ad-gallery gap-y-4 gap-x-4">
              <div className="flex flex-col ad-gallery-copy pb-clamp-22-26">
                <div className="w-full mx-auto sm:w-10/12 lg:w-5/6 lg:mr-0">
                  <Section.Title>Putting AD in control of their own image.</Section.Title>
                  <Section.Description>
                    <p>
                      After the brand launch, AD wanted to have their own photo assets—ones that reflected the spirit of
                      the organization in ways that stock photography couldn’t match. JK obliged by developing an
                      ownable photographic style for the brand and then we scouted locations, casted talent, captured
                      and retouched images, and created a custom photo library that put AD in full control of their
                      visual style.
                    </p>
                  </Section.Description>
                </div>
              </div>
              <div className="flex justify-center ad-gallery-image-1">
                <div data-aos="fade-up" className="sm:w-4/6 lg:w-full lg:mt-auto">
                  <GatsbyImage image={getImage(galleryOneImage)} alt="Gallery 1" />
                </div>
              </div>
              <div data-aos="fade-up" className="ad-gallery-image-2">
                <GatsbyImage image={getImage(galleryTwoImage)} alt="Gallery 2" />
              </div>
              <div data-aos="fade-up" className="ad-gallery-image-3">
                <GatsbyImage image={getImage(galleryThreeImage)} alt="Gallery 3" />
              </div>
              <div className="flex justify-center ad-gallery-image-4">
                <div data-aos="fade-up" className="sm:w-4/6 lg:w-full">
                  <GatsbyImage image={getImage(galleryFourImage)} alt="Gallery 4" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-50-68 pb-clamp-27-43">
          <div className="container flex justify-center">
            <div data-aos="fade-up" className="w-full xl:w-10/12">
              <Carousel.Quote client="ad" single={true}></Carousel.Quote>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <Pagination client="ad" />
      </main>
    </Layout>
  )
}

export default ADPage

export const adQuery = graphql`
  query ADPageQuery {
    designSystemDesktopImage: file(relativePath: { eq: "work/ad/design-system-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    designSystemMobileImage: file(relativePath: { eq: "work/ad/design-system-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageOneImage: file(relativePath: { eq: "work/ad/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/ad/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileOneImage: file(relativePath: { eq: "work/ad/mobile-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileTwoImage: file(relativePath: { eq: "work/ad/mobile-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileThreeImage: file(relativePath: { eq: "work/ad/mobile-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/ad/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/ad/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/ad/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/ad/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    videoPosterDesktopImage: file(relativePath: { eq: "work/ad/video-poster-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryOneImage: file(relativePath: { eq: "work/ad/gallery-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryTwoImage: file(relativePath: { eq: "work/ad/gallery-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryThreeImage: file(relativePath: { eq: "work/ad/gallery-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryFourImage: file(relativePath: { eq: "work/ad/gallery-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
